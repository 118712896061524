<template>
  <el-container class="product-manuals-container">
    <el-aside class="manuals-side-bar">
      <div class="manuals-list-css">
        <el-menu :default-active="manualName" size="mini" class="item-list-menu">
          <template v-for="(data, index) in menuData">
            <el-menu-item
                v-if="data.show"
                :key="data.value + index"
                :style="!data.isSubmenu ? 'padding-left: 20px;' : 'padding-left: 20px;'"
                :index="data.value"
                :isActive="manualName"
                @click="handleManualDisplay(data)">
                <span>{{$t(data.label)}}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </el-aside>
    <el-container>
      <el-main class="pdf-container" v-loading="loading">
        <el-empty v-if="manualName===''" :description="getDescription()"></el-empty>
        <div id="my-pdf" ref="pdfContainer"></div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PDFObject from 'pdfobject';
const timeoutObj = {
  WEB: 7000,
  MOBILE: 5000,
}
export default {
  data: function() {
    return {
      manualNameValue: '',
      selectedMenu: {},
      loading: false,
      productManualsData: [{ url: '' }]
    }
  },
  computed: {
    ...mapGetters("releaseNotes", {
      getUserProductManualsData: "getUserProductManualsData"
    }),
    ...mapGetters("superadmin/adminReleaseNotes", {
      getProductManualsData: "getProductManualsData"
    }),
    manualName() {
      return this.manualNameValue
    },
    menuData() {
      return [{ label: this.$t("PondLogs_Web"), value: "WEB", isSubmenu: false, show: true },
              { label: this.$t("PondLogs_Mobile"), value: "MOBILE", isSubmenu: false, show: true }
            ]
    }
  },
  watch: {
    "$i18n.locale": async function (newValue, oldValue) {
      try {
        this.loading = true;
        if (this.manualNameValue !== '') {
          const payload = {
              manual_type: this.manualNameValue,
              status: "ACTIVE",
              limit: 1,
              order_by: "released_date",
              language: this.$i18n.locale.toUpperCase() === "ES" ? "ES" : "EN"
            }
          await this.fetchUserProductManualPDF(payload);
          this.productManualsData = this.getUserProductManualsData
          await PDFObject.embed(this.productManualsData[0].url, "#my-pdf", { pdfOpenParams: { view: 'FitH', toolbar: 0, statusbar: 0, navpanes: 0, scrollbars: 0, search: 0 } });
          this.handleIframeLoad();
        }
      } catch (err) {
        this.manualNameValue = '';
        this.ehm__errorMessages(err, true);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapActions("superadmin/adminReleaseNotes", {
      fetchProductManualPDF: "fetchProductManualPDF"
    }),
    ...mapActions("releaseNotes", {
      fetchUserProductManualPDF: "fetchUserProductManualPDF"
    }),
    handleIframeLoad() {
      setTimeout(() => {
          this.loading = false;
        }, timeoutObj[this.manualName])
    },
    getDescription() {
      return JSON.parse(localStorage.getItem('curr-user-type')) === 'SUPER_ADMIN' ? 'No Manual Selected' : this.$t('Comn_no_manual_selected')
    },
    async handleManualDisplay(data) {
      try {
        if (this.manualNameValue !== data.value) {
          this.loading = true;
          this.manualNameValue = data.value;
          if (JSON.parse(localStorage.getItem("curr-user-type")) === "SUPER_ADMIN") {
            const payload = {
              manual_type: data.value,
              status: "ACTIVE",
              limit: 1,
              order_by: "released_date",
              language: "EN"
            }
            await this.fetchProductManualPDF(payload);
            this.productManualsData = this.getProductManualsData;
          } else {
            const payload = {
              manual_type: data.value,
              status: "ACTIVE",
              limit: 1,
              order_by: "released_date",
              language: this.$i18n.locale.toUpperCase() === "ES" ? "ES" : "EN"
            }
            await this.fetchUserProductManualPDF(payload);
            this.productManualsData = this.getUserProductManualsData;
          }
          await PDFObject.embed(this.productManualsData[0].url, "#my-pdf", { pdfOpenParams: { view: 'FitH', toolbar: 0, statusbar: 0, navpanes: 0, scrollbars: 0, search: 0 } });
          this.handleIframeLoad();
        }
      } catch (err) {
        this.manualNameValue = '';
        this.ehm__errorMessages(err, true);
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
.product-manuals-container {
  height: calc(100vh - 100px);
  border: 1px solid #eee;
  .manuals-side-bar {
    width: 250px !important;
    border-right: 1px solid #cbc7c7;
  }
  .manuals-list-css {
    .item-list-menu {
      height: auto;
      position: relative;
      text-transform: capitalize;
      .item-menu-aside-scroll {
        height: 100%;
      }
      .item-menu-aside-scroll-view {
        padding-right: 10px;
        padding-bottom: 50px;
      }
      .item-menu-aside-scroll-wrap {
        height: 100%;
        overflow-x: hidden;
      }
      .el-menu-item {
        height: 32px;
        line-height: 30px;
        @include responsiveProperty(font-size, 13px, 13px, 12px);
      }
      .el-menu-item.is-active {
        background-color: #ecf5ff;
        border-right: 2px solid #2558b7;
      }
    }
  }
}
.pdfobject-container {
   width: auto;
   height: 100%;
}
</style>
